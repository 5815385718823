import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const SignUp = lazy(() => import('../views/Auth/SignUp'));
const EmailVerification = lazy(() => import('../views/Auth/EmailVerification'));
const PhoneVerification = lazy(() => import('../views/ResendVerifyCode'));
const SignUpVerify = lazy(() => import('../views/Auth/SignUpVerify'));
const LogIn = lazy(() => import('../views/Auth/LogIn'));
const ForgotPassword = lazy(() => import('../views/ForgotPassword'));
const ResetPassword = lazy(() => import('../views/ResetPassword'));
const VerifyPhone = lazy(() => import('../views/Auth/VerifyPhone'));
const UnregisterSuccess = lazy(() => import('../views/Auth/UnregisterSuccess'));

function AuthRouter() {
    return (
        <Switch>
            <Route
                path="/sign-up"
                exact
                render={(props) => <SignUp {...props} />}
            />
            <Route
                path="/email/:guid/verification"
                render={(props) => <EmailVerification {...props} />}
            />
            <Route
                path="/sign-up/:guid/verify"
                render={(props) => <SignUpVerify {...props} />}
            />
            <Route
                path="/sign-up/:guid/resend-code"
                render={(props) => <PhoneVerification {...props} />}
            />
            <Route path="/login" render={(props) => <LogIn {...props} />} />
            <Route
                path="/forgot-password"
                render={(props) => <ForgotPassword {...props} />}
            />
            <Route
                path="/reset-password"
                render={(props) => <ResetPassword {...props} />}
            />
            <Route
                path="/verify-phone"
                render={(props) => <VerifyPhone {...props} />}
            />
            <Route
                path="/unregister-success"
                render={(props) => <UnregisterSuccess {...props} />}
            />
            {/* <Route component={NotFound} /> */}
            <Redirect to={window.location.pathname === '/' ? '/' : '/login'} />
        </Switch>
    );
}

export default AuthRouter;
