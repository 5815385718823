import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';
import NotoSansKRRegularWoff2 from './assets/fonts/NotoSansKR-Regular.woff2';
import NotoSansKRRegularWoff from './assets/fonts/NotoSansKR-Regular.woff';
import NotoSansKRRegularTtf from './assets/fonts/NotoSansKR-Regular.ttf';

const NotoSansCJKRegular = {
    fontFamily: 'NotoSansCJKRegular',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
    local('NotoSansCJKRegular'),
    url(${NotoSansKRRegularWoff2}) format('woff2'),
    url(${NotoSansKRRegularWoff}) format('woff'),
    url(${NotoSansKRRegularTtf}) format('ttf'),
  `,
};

const Theme = createTheme({
    overrides: {
        MuiTypography: {
            root: {
                wordBreak: 'keep-all',
            },
        },
        MuiCssBaseline: {
            '@global': {
                '@font-face': [NotoSansCJKRegular],
            },
        },
    },
    palette: {
        primary: {
            main: '#ff6a72',
            contrastText: '#fff',
        },
        secondary: {
            main: '#FFDBE6',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        fontFamily: 'Noto Sans KR, sans-serif',
        wordBreak: 'keep-all',
        h1: {
            fontFamily: 'Noto Sans KR Bold',
            fontSize: 60,
            fontWeight: 'bold',
            fontStretch: 'normal',
            lineHeight: 1.37,
            letterSpacing: '-0.4px',
        },
        h2: {
            fontFamily: 'Noto Sans KR Bold',
            fontSize: 50,
            fontWeight: 'bold',
            fontStretch: 'normal',
            lineHeight: 1.32,
            letterSpacing: '-0.3px',
        },
        h3: {
            fontFamily: 'Noto Sans KR Bold',
            fontSize: 42,
            fontWeight: 'bold',
            fontStretch: 'normal',
            lineHeight: 1.38,
            letterSpacing: '-0.3px',
        },
        h4: {
            fontFamily: 'Noto Sans KR Bold',
            fontSize: 32,
            fontWeight: 'bold',
            fontStretch: 'normal',
            lineHeight: 1.44,
            letterSpacing: '-0.53px',
        },
        h5: {
            // fontFamily: 'Noto Sans KR Bold',
            fontSize: 24,
            fontWeight: 'bold',
            fontStretch: 'normal',
            lineHeight: 1.5,
            letterSpacing: '-0.4px',
        },
        h6: {
            // fontFamily: 'Noto Sans KR Bold',
            fontSize: 22,
            fontWeight: 'bold',
            fontStretch: 'normal',
            lineHeight: 1.45,
            letterSpacing: '-0.4px',
        },
        subtitle1: {
            fontSize: 18,
            lineHeight: 1.5,
            letterSpacing: '-0.3px'
        },
        subtitle2: {
            fontSize: 14,
            lineHeight: 1.71,
            letterSpacing: '-0.3px'
        },
        body2: {
            fontFamily: 'Noto Sans KR Light'
        }
    },
    spacing: 4,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1440,
            xl: 1536,
        },
    }
});

export default Theme;
