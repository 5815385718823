import { createActions } from 'redux-actions';

export const { handleSignature, clearSignature } = createActions({
    handleSignature: (id, signature, userId) => ({
        id,
        signature,
        userId,
    }),
    clearSignature: () => ({}),
});
