import { handleActions } from 'redux-actions';
// import i18n from '../../i18n';

// import {
//     SET_AUTH_TOKEN,
//     SET_USER
// } from './actions';

const initialState = {
    user: {
        id: undefined,
        guid: undefined,
        email: '',
        name: '',
        phonenumber: '',
        birthday: undefined,
        country: '',
        phoneVerified: '',
        callbackAddress: '',
        marketingStatus: 0,
    },
    signatures: [],
    promises: [],
    archivedPromises: [],
    isLoggedIn: false,
    ipAddress: undefined,
    lang: '',
    version: '',
    snackMessage: '',
};

export default handleActions(
    {
        updateUser: (state, action) => ({ ...state, user: action.payload }),
        handleLoggedIn: (state, action) => ({
            ...state,
            isLoggedIn: action.payload,
        }),
        getSignatures: (state, action) => ({
            ...state,
            signatures: action.payload,
        }),
        removeSignature: (state, action) => ({
            ...state,
            signatures: [
                ...state.signatures.filter(
                    (signature) => signature.id !== action.payload.id,
                ),
            ],
        }),
        getPromises: (state, action) => ({
            ...state,
            promises: action.payload,
        }),
        removePromise: (state, action) => ({
            ...state,
            promises: [
                ...state.promises.filter(
                    (promise) => promise.id !== action.payload.id,
                ),
            ],
        }),
        updatePromise: (state, action) => ({
            ...state,
            promises: [
                ...state.promises.map((promise) => {
                    if (promise && promise.id !== action.payload.id) {
                        return promise;
                    }

                    return {
                        ...promise,
                        ...action.payload,
                    };
                }),
            ],
        }),
        getArchivedPromises: (state, action) => ({
            ...state,
            archivedPromises: action.payload,
        }),
        setIpAddress: (state, action) => ({
            ...state,
            ipAddress: action.payload,
        }),
        setLanguage: (state, action) => {
            // i18n.changeLanguage(action.payload)
            return {
                ...state,
                lang: action.payload,
            };
        },
        setVersion: (state, action) => ({
            ...state,
            version: action.payload,
        }),
        clearAuth: () => ({
            ...initialState,
        }),
        setSnackMessage: (state, action) => ({
            ...state,
            snackMessage: action.payload,
        }),
        setMarketingStatus: (state, action) => ({
            ...state,
            user: {
                ...state.user,
                marketingStatus: action.payload,
            }
        })
    },
    initialState,
);
