import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import ls from 'local-storage';
import { Backdrop, CircularProgress } from '@material-ui/core';
import api from '../lib/api';
import theme from '../Theme';

const BorrowFromFriend = lazy(() => import('../views/BorrowFromFriend'));
const LoanToFriend = lazy(() => import('../views/LoanToFriend'));
const Client = lazy(() => import('../views/Client'));
const Freelancer = lazy(() => import('../views/Freelancer'));
const Sell = lazy(() => import('../views/Sell'));
const Purchase = lazy(() => import('../views/Purchase'));
const LoginRequired = lazy(() => import('../views/LoginRequired'));
const PromiseCompletion = lazy(() => import('../views/PromiseCompletion'));
const ReviewPromiseConfirm = lazy(() =>
    import('../views/ReviewPromiseConfirm'),
);
const UnAuthMyPromise = lazy(() => import('../views/UnAuthMyPromise'));
const Chat = lazy(() => import('../views/Chat'));
const TemplatePromise = lazy(() => import('../views/TemplatePromise'));
const SinglePromise = lazy(() => import('../views/SinglePromise'));

class UnAuthAppRouter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadable: true,
        };
    }

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        const accessToken = params.get('a');
        const refreshToken = params.get('r');
        if (accessToken && refreshToken) {
            api.setAccessToken(accessToken);
            ls.set('refreshToken', refreshToken);
        } else {
            this.setState({
                loadable: false,
            });
        }
    }

    render() {
        const { path } = this.props.match;
        const { loadable } = this.state;
        if (!loadable) {
            return (
                <Backdrop
                    open
                    style={{
                        zIndex: theme.zIndex.drawer + 1,
                        color: '#fff',
                    }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            );
        }
        return (
            <Switch>
                <Route
                    path={`${path}/borrow`}
                    exact
                    render={(props) => <BorrowFromFriend {...props} />}
                />
                <Route
                    path={`${path}/borrow/:id`}
                    exact
                    render={(props) => <BorrowFromFriend {...props} />}
                />
                <Route
                    path={`${path}/loan`}
                    exact
                    render={(props) => <LoanToFriend {...props} />}
                />
                <Route
                    path={`${path}/loan/:id`}
                    exact
                    render={(props) => <LoanToFriend {...props} />}
                />
                <Route
                    path={`${path}/client`}
                    exact
                    render={(props) => <Client {...props} />}
                />
                <Route
                    path={`${path}/client/:id`}
                    exact
                    render={(props) => <Client {...props} />}
                />
                <Route
                    path={`${path}/freelancer`}
                    exact
                    render={(props) => <Freelancer {...props} />}
                />
                <Route
                    path={`${path}/freelancer/:id`}
                    exact
                    render={(props) => <Freelancer {...props} />}
                />
                <Route
                    path={`${path}/sell`}
                    exact
                    render={(props) => <Sell {...props} />}
                />
                <Route
                    path={`${path}/sell/:id`}
                    exact
                    render={(props) => <Sell {...props} />}
                />
                <Route
                    path={`${path}/purchase`}
                    exact
                    render={(props) => <Purchase {...props} />}
                />
                <Route
                    path={`${path}/purchase/:id`}
                    exact
                    render={(props) => <Purchase {...props} />}
                />
                <Route
                    path={`${path}/promise/:id/completion/:type`}
                    render={(props) => <PromiseCompletion {...props} />}
                />
                <Route
                    path={`${path}/promise-review-confirm/:id`}
                    exact
                    render={(props) => <ReviewPromiseConfirm {...props} />}
                />
                <Route
                    path={`${path}/my-promise`}
                    render={(props) => <UnAuthMyPromise {...props} />}
                />
                <Route
                    path={`${path}/promise/:id/chat`}
                    render={(props) => <Chat {...props} />}
                />
                <Route
                    path={`${path}/single`}
                    exact
                    render={(props) => <SinglePromise {...props} />}
                />
                <Route
                    path={`${path}/single/:id`}
                    exact
                    render={(props) => <SinglePromise {...props} />}
                />
                <Route
                    path={`${path}/epromise`}
                    exact
                    render={(props) => <TemplatePromise {...props} />}
                />
                <Route
                    path={`${path}/epromise/:id`}
                    exact
                    render={(props) => <TemplatePromise {...props} />}
                />
                <Route component={LoginRequired} />
            </Switch>
        );
    }
}

export default UnAuthAppRouter;
