import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import CacheBuster from 'react-cache-buster';
import {
    withStyles,
    Backdrop,
    CircularProgress,
    Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import ls from 'local-storage';
import { isIOS } from 'react-device-detect';
import history from './views/history';
import { updatePosition } from './redux/auth/actions';
import { handleLoading, handleError, handleSuccess } from './redux/common/actions';
import { version } from '../package.json';
import 'App.css';
import { AppRouter, AuthRouter, UnAuthAppRouter } from './routes';

const Home = lazy(() => import('./views/Home'));
const Introduce = lazy(() => import('./views/Market/Introduce'));
const Effort = lazy(() => import('./views/Market/Effort'));
const MobileFabricProvider = lazy(() => import('./views/MobileFabricProvider'));
const PdfGenerator = lazy(() => import('./views/PdfGenerator'));
const QrSigning = lazy(() => import('./views/QrSigning'));

const APP_STORE_URL = 'https://itunes.apple.com/us/app/cardiogram/id1614912231?ls=1&mt=8';
const GOOGLE_PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=me.eztrust.pinkiiswear';

const useStyles = (theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});


class App extends React.Component {
    constructor(props) {
        super(props);
        this.isProduction = process.env.REACT_APP_ENV !== 'development';
        // store url pathname in local storage, so we can use it once sing-up/in is done.
        if (!ls.get('isLoggedIn')) {
            if (!window.location.search) {
                ls.set('redirectMe', window.location.pathname);
            }
        }
    }

    componentDidMount() {
        const { lang } = this.props;
        if (!lang) {
            this.props.updatePosition();
        }
        this.props.handleLoading(false);
    }

    renderLoading = () => {
        const { classes } = this.props;
        return (
            <div>
                <Backdrop open className={classes.backdrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        );
    };

    render() {
        const isLoggedIn = this.props.isLoggedIn && ls.get('isLoggedIn');
        const {showError, showSuccess, errMessage, t, i18n} = this.props;

        return (
            <CacheBuster
                currentVersion={version}
                isEnabled={this.isProduction} // If false, the library is disabled.
                isVerboseMode={false} // If true, the library writes verbose logs to console.
            >
                <BrowserRouter history={history}>
                    <Suspense fallback={this.renderLoading()}>
                        <Switch>
                            <Route
                                exact
                                path="/app-preview"
                                component={() => {
                                    if (isIOS) {
                                        window.location.replace(APP_STORE_URL);
                                    } else {
                                        window.location.replace(GOOGLE_PLAY_STORE_URL);
                                    };
                                }}
                            />
                            <Route
                                exact
                                path="/en"
                                render={(props) => <Home {...props} />}
                            />
                            <Route
                                exact
                                path="/:locale(en|ko)?"
                                render={(props) => <Home {...props} />}
                            />
                            <Route
                                exact
                                path="/introduce"
                                render={(props) => <Introduce {...props} />}
                            />
                            <Route
                                exact
                                path="/legal-effect"
                                render={(props) => <Effort {...props} />}
                            />
                            <Route
                                path="/editor/:token/:id"
                                render={(props) => (
                                    <MobileFabricProvider {...props} />
                                )}
                            />
                            <Route
                                path="/pdf/:token/:id"
                                render={(props) => (
                                    <PdfGenerator {...props} />
                                )}
                            />
                            <Route
                                path="/qr-sign/"
                                render={(props) => <QrSigning {...props} />}
                            />
                            {isLoggedIn !== true ? (
                                <Switch>
                                    <Route
                                        path="/u"
                                        render={(props) => (
                                            <UnAuthAppRouter {...props} />
                                        )}
                                    />
                                    <AuthRouter />
                                </Switch>
                            ) : (
                                <AppRouter />
                            )}
                        </Switch>
                        <Snackbar
                            open={showError}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            autoHideDuration={5000}
                            onClose={() => this.props.handleError(false, '')}
                        >
                            <MuiAlert elevation={6} variant="filled" severity="warning">
                                {errMessage}
                            </MuiAlert>
                        </Snackbar>
                        <Snackbar
                            open={showSuccess}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            autoHideDuration={5000}
                            onClose={() => this.props.handleSuccess(false, '')}
                        >
                            <MuiAlert elevation={6} variant="filled" severity="success">
                                {errMessage}
                            </MuiAlert>
                        </Snackbar>
                    </Suspense>
                </BrowserRouter>
            </CacheBuster>
        );
    }
}

const mapStateToProps = (state) => ({
    position: state.auth.position,
    lang: state.auth.lang,
    isLoggedIn: state.auth.isLoggedIn,
    showError: state.common.showError,
    errMessage: state.common.message,
});

const mapDispatchToProps = (dispatch) => ({
    updatePosition: () => dispatch(updatePosition()),
    handleLoading: (loading) => dispatch(handleLoading(loading)),
    handleError: (showError, message) =>
        dispatch(handleError(showError, message)),
    handleSuccess: (showSuccess, message) =>
        dispatch(handleSuccess(showSuccess, message)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(useStyles)(App));
