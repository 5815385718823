import isJWT from 'validator/lib/isJWT';
import jWTDecode from 'jwt-decode';
import ls from 'local-storage';
import axios from 'axios';
import _ from 'lodash';
import history from '../views/history';
import http from './http-common';

let accessToken;

const authInstance = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER_URL}/api`,
    headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    },
});

authInstance.interceptors.request.use(
    (config) => {
        return {
            ...config,
            headers: {
                ...config.headers,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        };
    },
    (error) => {
        return Promise.reject(error);
    },
);

const ipInstance = axios.create({
    baseURL: `https://ipapi.co/json`,
});

const newToken = () => {
    return new Promise((res, rej) => {
        const refreshToken = ls.get('refreshToken');
        authInstance
            .post('users/token/', { refreshToken })
            .then((resp) => {
                res(resp);
            })
            .catch((error) => {
                rej(error);
            });
    });
};

const tokenExpired = (token) => {
    const { exp } = jWTDecode(token);
    return exp * 1000 <= Date.now();
};

http.interceptors.request.use(
    async (config) => {
        const refreshToken = ls.get('refreshToken');
        if (!accessToken && !refreshToken) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject('Access token cannot be null');
        }

        if (isJWT(refreshToken) && !accessToken) {
            const resp = await newToken();
            if (resp.data.success) {
                accessToken = resp.data.data.accessToken;
            }
            // accessToken = (await newToken()).data.accessToken
        }

        if (!isJWT(accessToken)) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject('Access token is invalid');
        }

        if (tokenExpired(accessToken)) {
            const resp = await newToken();
            if (resp.data.success) accessToken = resp.data.data.accessToken;
        }
        const multipartUrls = [
            'debt/promise',
            'debt/promise/fourth',
            '/single/promise',
            'debt/promise/fifth/mobile',
            'template/promise',
        ];
        const contentType =
            config.url in multipartUrls ? 'multipart/form-data' : 'application';
        const additionHeaders = {
            authorization: `Bearer ${accessToken}`,
            'Content-Type': contentType,
            'Access-Control-Allow-Origin': '*',
        };

        return {
            ...config,
            headers: {
                ...config.headers,
                ...additionHeaders,
            },
        };
    },
    (error) => {
        return Promise.reject(error);
    },
);

http.interceptors.response.use(
    (resp) => {
        return resp;
    },
    (error) => {
        if (error.response) {
            const { status } = error.response;
            if (status === 404) {
                ls.set('isLoggedIn', false);
                ls.set('refreshToken', null);
                history.push('/');
            }
        }
        return Promise.reject(error);
    },
);

class APIs {
    createFormData = (fields) => {
        const formData = new FormData();
        _.forEach(fields, (value, key) => {
            console.log(`key: ${key} value: ${value}`);
            formData.append(key, value);
        });
        return formData;
    };

    setAccessToken = (token) => {
        accessToken = token;
    };

    checkPhone = (params) => authInstance.post('users/phone', params);

    userRegister = (params) => authInstance.post('users/', params);

    recipientRegister = (params) =>
        authInstance.post('users/recipient/', params);

    getRecipientToken = (params) =>
        authInstance.post('users/recipient-token/', params);

    userLogin = (params) => authInstance.post('users/login/', params);

    verifyEmail = (params) => authInstance.post('users/verify-email/', params);

    resendOTP2Email = (params) => authInstance.post('users/resent-otp', params);

    forgotPassword = (params) =>
        authInstance.post('users/forgot-password', params);

    verifyToken = (params) => authInstance.post('users/verify-token', params);

    verifyOtp = (params) => authInstance.post('users/verify-otp', params);

    resetPassword = (params) =>
        authInstance.post('users/reset-password', params);

    sendVerificationCode = (params) =>
        authInstance.post('/users/phone-verify', params);

    updatePassword = (params) => http.post('users/update-password', params);

    updateName = (params) => http.post('/users/update-name', params);

    updateEmail = (params) => http.post('/users/update-email', params);

    sendEmailCode = (params) => http.post('/users/send-email-code', params);

    updatePhoneNumber = (params) =>
        http.post('/users/update-phone-number', params);

    updateLang = (params) => http.post('/user/update-lang', params);

    updateMarketingConsent = (params) => http.post('/user/update-marketing', params);

    resendVerifyCode = () => http.post('/users/resend-code');

    verifyCode = (params) => http.post('/users/verify-code', params);

    userLogout = () => http.delete('users/logout');

    deleteAccount = () => http.delete('users/');

    getCurrentUserInfo = () => http.get('users/me/');

    getUserInfo = (params) => http.get('users/', { params });

    updateProfileImg = (params) =>
        http.post('users/profile', this.createFormData(params));

    kcpData = () => authInstance.get('/users/kcp-data');

    verifyPhone = (params) => http.post('/users/verify-phone', params);

    getUsersByHost = (params) => http.get('users/by-host', { params });

    getUsersByPhoneNumber = (params) =>
        http.get('users/by-phonenumber', { params });

    getUsersByUserId = (params) =>
        http.get('users/by-userid', { params });

    updateNotiSetting = (params) => http.post('/users/notification', params);

    allowNoti = () => http.post('/users/notification/allow');

    debtFirstStep = (params) => http.post('debt/promise/first', params);

    debtSecondStep = (params) => http.post('debt/promise/second', params);

    debtThirdStep = (params) => http.post('debt/promise/third', params);

    debtFourthStep = (params) =>
        http.post('debt/promise/fourth', this.createFormData(params));

    debtPromise = (params) =>
        http.post('debt/promise', this.createFormData(params));

    debtAgree = (params) => http.post('debt/agreement', params);

    debtRequestSign = (params) => http.post('/debt/promise/request', params);

    debtUpload = (params) =>
        http.post('/debt/upload/', this.createFormData(params));

    uploadCertification = (params) =>
        http.post('/debt/certification/', this.createFormData(params));

    debtFirstStepMobile = (params) =>
        http.post('debt/promise/first/mobile', params);

    debtSecondStepMobile = (params) =>
        http.post('debt/promise/second/mobile', params);

    debtThirdStepMobile = (params) =>
        http.post('debt/promise/third/mobile', params);

    debtFourthStepMobile = (params) =>
        http.post('debt/promise/fourth/mobile', params);

    debtFifthStepMobile = (params) =>
        http.post('debt/promise/fifth/mobile', this.createFormData(params));

    debtSixthStepMobile = (params) =>
        http.post('/debt/promise/sixth/mobile', this.createFormData(params));

    debtAgreementMobile = (params) =>
        http.post('/debt/promise/agreement', params);

    debtReviewAgreementMobile = (params) =>
        http.post('/debt/promise/review/agreement', params);

    debtConsent = (params) => http.post('/debt/promise/consent', params);

    debtReviewConsent = (params) =>
        http.post('/debt/promise/review/consent', params);

    singleFirstStep = (params) => http.post('/single/firstStep', params);

    singleSecondStep = (params) => http.post('/single/secondStep', params);

    singleDueDateStep = (params) => http.post('/single/dueDateStep', params);

    singlePromiseEditor = (params) => http.post('/single/editor', params);

    singleAgreement = (params) => http.post('/single/agreement', params);

    singlePromiseSign = (params) => http.post('/single/sign', params);

    singlePromiseReview = (params) => http.post('/single/review', params);

    templateFirstStep = (params) => http.post('/template/firstStep', params);

    templateSecondStep = (params) => http.post('/template/secondStep', params);

    templateThirdStep = (params) =>
        http.post('/template/thirdStep', this.createFormData(params));

    getPdfFile = (id) => http.get(`/template/pdf/${id}/`);

    templateAgree = (params) =>
        http.post('/template/agreement', this.createFormData(params));

    templateConsent = (params) => http.post('/template/consent', params);

    templateDetail = (id) => http.get(`/template/list/${id}`);

    templateUpdateRecipientInfo = (params) =>
        http.post('/template/updateRecipientInfo', params);


    editorFirstStep = (params) => http.post('/editor/firstStep', this.createFormData(params));

    editorSecondStep = (params) => http.post('/editor/secondStep', this.createFormData(params));

    editorDetail = (id) => http.get(`/editor/${id}`);

    freelanceFirstStep = (params) => http.post('/freelance/firstStep', params);

    freelanceSecondStep = (params) =>
        http.post('/freelance/secondStep', params);

    freelanceThirdStep = (params) => http.post('/freelance/thirdStep', params);

    freelanceFourthStep = (params) =>
        http.post('/freelance/fourthStep', params);

    freelanceFifthStep = (params) => http.post('/freelance/fifthStep', params);

    saleFirstStep = (params) => http.post('/sale/firstStep', params);

    saleSecondStep = (params) => http.post('/sale/secondStep', params);

    saleThirdStep = (params) =>
        http.post('/sale/thirdStep', this.createFormData(params));

    saleFourthStep = (params) => http.post('/sale/fourthStep', params);

    reviewFirstStep = (params) => http.post('/review/firstStep', params);

    reviewSecondStep = (params) => http.post('/review/secondStep', params);

    debtDetail = (id) => http.get(`debt/${id}/`);

    promiseList = (params) => http.get('promise', { params });

    categorizedPromise = (params) => http.get('/promise/category', { params });

    archivedPromiseList = () => http.get('promise/archive');

    archivePromise = (params) => http.post('promise/archive', params);

    deletePromise = (parmas) => http.post('/promise/delete', parmas);

    ratePromise = (params) => http.post('promise/rate', params);

    getUserRate = () => http.get('promise/rate');

    promiseLog = (params) => http.get(`promise/logs`, { params });

    addPromiseLog = (params) => http.post('/promise/log', params);

    newSignature = (params) => http.post('signature/', params);

    signatureList = () => http.get('signature/');

    setDefaultSignature = (params) => http.post('/signature/default', params);

    signatureDelete = (params) => http.post('signature/disable', params);

    addDeviceWeb = (params) => http.post('/device/web/', params);

    addDeviceApns = (params) => http.post('/device/apns/', params);

    getNotification = (params) => http.post('/', params);

    // ip api
    getLocation = () => ipInstance.get('/');

    // question
    sendQuestion = (params) => authInstance.post('/question', params);

    // message
    getMessageList = () => http.get('/messages');

    individualDelete = (id) => http.delete(`messages/${id}/`);

    allDelete = () => http.delete('/messages');

    readNotify = () => http.post('/messages/read');

    getUnreadNotify = () => http.post('/messages/unread');

    promiseCreatorInfo = (params) =>
        http.post('/promise/creatorInfo', params);

    getSignature = (id) => http.get(`/signatureById/${id}`);
}

export default new APIs();
