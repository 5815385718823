import { handleActions } from 'redux-actions';

const initialState = {
    showError: false,
    showSuccess: false,
    message: '',
    singlePromise: {
        id: undefined,
        promisorName: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        title: '',
        senderId: undefined,
        recipientId: undefined,
        description: '',
        attachedFile: undefined,
        attachedFileName: '',
        attachedFileSize: '',
        signAgree: false,
        signature: undefined,
        signatureId: undefined,
        editing: 0,
    },
    sentPromises: [],
    receivedPromises: [],
    fabricCanvas: {
        backgroundColor: '#FFFFFF',
        backgroundImage: '',
        height: 500,
        width: 400,
        moving: '', // a flag for movable
    },
    fabricObjects: [],
    loading: false,
    notificationBar: false,
    notificationList: [],
    readNotification: false,
    // Don't show messages in here.
    shownMessages: [],
};

export default handleActions(
    {
        handleError: (state, action) => ({
            ...state,
            showError: action.payload.showError,
            message: action.payload.message,
        }),
        handleSuccess: (state, action) => ({
            ...state,
            showSuccess: action.payload.showSuccess,
            message: action.payload.message,
        }),
        handleSinglePromise: (state, action) => ({
            ...state,
            singlePromise: {
                ...state.singlePromise,
                ...action.payload,
            },
        }),
        clearSinglePromise: (state) => ({
            ...state,
            singlePromise: {
                ...initialState.singlePromise,
            },
        }),
        handleSentPromises: (state, action) => ({
            ...state,
            sentPromises: action.payload,
        }),
        removeSentPromise: (state, action) => ({
            ...state,
            sentPromises: [
                ...state.sentPromises.filter(
                    (promise) => promise.id !== action.payload.id,
                ),
            ],
        }),
        updateSentPromise: (state, action) => ({
            ...state,
            sentPromises: [
                ...state.sentPromises.map((promise) => {
                    if (promise && promise.id !== action.payload.id) {
                        return promise;
                    }

                    return {
                        ...promise,
                        ...action.payload,
                    };
                }),
            ],
        }),
        handleReceivedPromises: (state, action) => ({
            ...state,
            receivedPromises: action.payload,
        }),
        removeReceivedPromise: (state, action) => ({
            ...state,
            receivedPromises: [
                ...state.receivedPromises.filter(
                    (promise) => promise.id !== action.payload.id,
                ),
            ],
        }),
        updateReceivedPromise: (state, action) => ({
            ...state,
            receivedPromises: [
                ...state.receivedPromises.map((promise) => {
                    if (promise && promise.id !== action.payload.id) {
                        return promise;
                    }

                    return {
                        ...promise,
                        ...action.payload,
                    };
                }),
            ],
        }),
        removePromise: (state, action) => ({
            ...state,
            sentPromises: [
                ...state.sentPromises.filter(
                    (promise) => promise.id !== action.payload.id,
                ),
            ],
            receivedPromises: [
                ...state.receivedPromises.filter(
                    (promise) => promise.id !== action.payload.id,
                ),
            ],
        }),
        updatePromise: (state, action) => ({
            ...state,
            sentPromises: [
                ...state.sentPromises.map((promise) => {
                    if (promise && promise.id !== action.payload.id) {
                        return promise;
                    }

                    return {
                        ...promise,
                        ...action.payload,
                    };
                }),
            ],
            receivedPromises: [
                ...state.receivedPromises.map((promise) => {
                    if (promise && promise.id !== action.payload.id) {
                        return promise;
                    }

                    return {
                        ...promise,
                        ...action.payload,
                    };
                }),
            ],
        }),
        addFabricObject: (state, action) => ({
            ...state,
            fabricObjects: [...state.fabricObjects, action.payload],
        }),
        removeFabricObject: (state, action) => ({
            ...state,
            fabricObjects: [
                ...state.fabricObjects.filter(
                    (object) => object.id !== action.payload.id,
                ),
            ],
        }),
        updateFabricObject: (state, action) => ({
            ...state,
            fabricObjects: [
                ...state.fabricObjects.map((object) => {
                    if (object.id !== action.payload.id) {
                        return object;
                    }
                    return {
                        ...object,
                        ...action.payload,
                    };
                }),
            ],
        }),
        setFabricObjects: (state, action) => ({
            ...state,
            fabricObjects: action.payload
                ? action.payload
                : initialState.fabricObjects,
        }),
        clearFabricObjects: (state) => ({
            ...state,
            fabricObjects: [],
        }),
        updateFabricCanvas: (state, action) => ({
            ...state,
            fabricCanvas: {
                ...state.fabricCanvas,
                ...action.payload,
            },
        }),
        clearFabricCanvas: (state) => ({
            ...state,
            fabricCanvas: {
                ...initialState.fabricCanvas,
            },
        }),
        handleLoading: (state, action) => ({
            ...state,
            loading: action.payload,
        }),
        handleNotification: (state, action) => ({
            ...state,
            notificationBar: action.payload,
        }),
        handleNotificationList: (state, action) => ({
            ...state,
            notificationList: action.payload,
        }),
        removeNotification: (state, action) => ({
            ...state,
            notificationList: [
                ...state.notificationList.filter(
                    (promise) => promise.id !== action.payload,
                ),
            ],
        }),
        clearNotification: (state) => ({
            ...state,
            notificationList: [],
        }),
        handleReadNotification: (state, action) => ({
            ...state,
            readNotification: action.payload,
        }),
        clearCommon: () => ({
            ...initialState,
        }),
        addMessageToShown: (state, action) => {
            if (!state.shownMessages) {
                return {
                    ...state,
                    shownMessages: [
                        action.payload
                    ]
                }
            }
            return {
                ...state,
                shownMessages: [
                    ...state.shownMessages,
                    action.payload,
                ]
            }
        }
    },
    initialState,
);
