import React, {lazy} from 'react';
import {connect} from 'react-redux';
import {
    Redirect,
    Route,
    Switch,
} from "react-router-dom";
import ls from 'local-storage';
import {
    withStyles,
    Backdrop,
    CircularProgress
} from '@material-ui/core';
import {version} from '../../package.json';
import {setVersion} from '../redux/auth/actions';
import {clearCommon} from '../redux/common/actions';
import {clearDebt} from '../redux/debt/actions';
import {clearSignature} from '../redux/signature/actions';
import {clearTemplate} from '../redux/template/action';
const CustomerSupport = lazy(() => import("../views/CustomerSupport"));
const MyPromise = lazy(() => import('../views/MyPromise'));
const Money = lazy(() => import('../views/Money'));
const Chat = lazy(() => import('../views/Chat'));
const PromiseCompletion = lazy(() => import('../views/PromiseCompletion'));
const Client = lazy(() => import('../views/Client'));
const Freelancer = lazy(() => import('../views/Freelancer'));
const Sell = lazy(() => import('../views/Sell'));
const Purchase = lazy(() => import('../views/Purchase'));
const Editor = lazy(() => import('../views/Editor'));
const EditorConfirm = lazy(() => import('../views/EditorConfirm'));
const PromiseConfirm = lazy(() => import('../views/PromiseConfirm'));
const ReviewPromiseConfirm = lazy(() => import('../views/ReviewPromiseConfirm'));
const Notification = lazy(() => import('../views/Notification'));
const SinglePromise = lazy(() => import('../views/SinglePromise'));
const TemplatePromise = lazy(() => import('../views/TemplatePromise'));
const BorrowFromFriend = lazy(() => import('../views/BorrowFromFriend'));
const LoanToFriend = lazy(() => import('../views/LoanToFriend'));
const PromiseLog = lazy(() => import('../views/PromiseLog'));
const Signature = lazy(() => import('../views/Signature'));
const Archived = lazy(() => import('../views/Archived'));
const Setting = lazy(() => import('../views/Setting'));
const UpdateName = lazy(() => import('../views/UpdateName'));
const UpdateEmail = lazy(() => import('../views/UpdateEmail'));
const UpdatePassword = lazy(() => import('../views/UpdatePassword'));
const UpdateMarketingConsent = lazy(() => import('../views/UpdateMarketingConsent'));
const UpdatePhoneNumber = lazy(() => import('../views/UpdatePhoneNumber'));
const UpdateLang = lazy(() => import('../views/UpdateLang'));
const NotFound = lazy(() => import('../views/NotFound'));
const PDFViewer = lazy(() => import('../views/PDFViewer'));
const Menu = lazy(() => import('../views/Menu'));
const DeletePromise = lazy(() => import('../views/DeletePromise'));
const DeleteAccount = lazy(() => import('../views/DeleteAccount'));
const VerifyPhone = lazy(() => import('../views/Auth/VerifyPhone'));
const UpdateAccountByVerifyPhone = lazy(() => import('../views/UpdateAccountByVerifyPhone'));


const useStyles = theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    }
});

class AppRouter extends React.Component {
    constructor(props) {
        super(props);
        this.isProduction = process.env.NODE_ENV !== 'development';
    }

    componentDidMount() {
        const currentVersion = this.props.version;
        console.log(">>>", currentVersion, version, this.isProduction);
        if (currentVersion !== version && this.isProduction) {
            console.log("clear>>>");
            this.props.clearCommon();
            this.props.clearDebt();
            this.props.clearSignature();
            this.props.clearTemplate();
            this.props.setVersion(version);
        }
    }

    render() {
        const {classes} = this.props;
        const currentVersion = this.props.version;
        if (currentVersion !== version && this.isProduction) {
            return (
                <div>
                    <Backdrop open={true} className={classes.backdrop}>
                        <CircularProgress color={"inherit"}/>
                    </Backdrop>
                </div>
            )
        }
        return (
            <Switch>
                <Route
                    path={"/customer-support"}
                    render={props => <CustomerSupport {...props}/>}
                />
                {/*<Route
                    path={"/promise-list"}
                    render={props => <PromiseList {...props}/>}
                />*/}
                <Route
                    path={"/promise/:id/chat"}
                    render={props => <Chat {...props}/>}
                />
                <Route
                    path={'/promise/:id/completion/:type'}
                    render={props => <PromiseCompletion {...props}/>}
                />
                <Route
                    path={'/money'}
                    render={props => <Money {...props}/>}
                >
                </Route>
                <Route
                    path="/single"
                    exact
                    render={props => <SinglePromise {...props}/>}
                />
                <Route
                    path={'/single/:id'}
                    exact
                    render={props => <SinglePromise {...props}/>}
                />
                <Route
                    path="/epromise"
                    exact
                    render={props => <TemplatePromise {...props}/>}
                />
                <Route
                    path={'/epromise/:id'}
                    exact
                    render={props => <TemplatePromise {...props}/>}
                />
                <Route
                    path="/borrow"
                    exact
                    render={props => <BorrowFromFriend {...props}/>}
                />
                <Route
                    path={'/borrow/:id'}
                    exact
                    render={props => <BorrowFromFriend {...props}/>}
                />
                <Route
                    path={'/loan'}
                    exact
                    render={props => <LoanToFriend {...props}/>}
                />
                <Route
                    path={'/loan/:id'}
                    exact
                    render={props => <LoanToFriend {...props}/>}
                />
                <Route
                    path={'/client'}
                    exact
                    render={props => <Client {...props}/>}
                />
                <Route
                    path={'/client/:id'}
                    exact
                    render={props => <Client {...props}/>}
                />
                <Route
                    path={'/freelancer'}
                    exact
                    render={props => <Freelancer {...props}/>}
                />
                <Route
                    path={'/freelancer/:id'}
                    exact
                    render={props => <Freelancer {...props}/>}
                />
                <Route
                    path={'/sell'}
                    exact
                    render={props => <Sell {...props}/>}
                />
                <Route
                    path={'/sell/:id'}
                    exact
                    render={props => <Sell {...props}/>}
                />
                <Route
                    path={'/purchase'}
                    exact
                    render={props => <Purchase {...props}/>}
                />
                <Route
                    path={'/purchase/:id'}
                    exact
                    render={props => <Purchase {...props}/>}
                />
                <Route
                    path={'/editor'}
                    exact
                    render={props => <Editor {...props}/>}
                />
                <Route
                    path={'/editor-confirm/:id'}
                    exact
                    render={props => <EditorConfirm {...props}/>}
                />
                <Route
                    path={'/promise/:id/logs/:type'}
                    render={props => <PromiseLog {...props}/>}
                />
                <Route
                    path={'/promise-confirm/:id'}
                    exact
                    render={props => <PromiseConfirm {...props}/>}
                />
                <Route
                    path={'/promise-review-confirm/:id'}
                    exact
                    render={props => <ReviewPromiseConfirm {...props}/>}
                />
                <Route
                    path={'/my-sign'}
                    render={props => <Signature {...props}/>}
                />
                <Route
                    path="/archived"
                    render={props => <Archived {...props}/>}
                />
                <Route
                    path="/notification"
                    render={props => <Notification {...props}/>}
                />
                <Route
                    path={'/my-promise'}
                    render={props => <MyPromise {...props}/>}
                />
                <Route
                    path="/setting"
                    render={props => <Setting {...props}/>}
                />
                <Route
                    path="/update-name"
                    render={props => <UpdateName {...props}/>}
                />
                <Route
                    path="/update-email"
                    render={props => <UpdateEmail {...props}/>}
                />
                <Route
                    path="/update-password"
                    render={props => <UpdatePassword {...props}/>}
                />
                <Route
                    path="/update-marketing"
                    render={props => <UpdateMarketingConsent {...props}/>}
                />
                <Route
                    path="/update-phone-number"
                    render={props => <UpdatePhoneNumber {...props}/>}
                />
                <Route
                    path="/update-lang"
                    render={props => <UpdateLang {...props}/>}
                />
                <Route
                    path={'/pdf-viewer/:id'}
                    render={props => <PDFViewer {...props}/>}
                />
                <Route
                    path={'/menu'}
                    render={props => <Menu {...props}/>}
                />
                <Route
                    path={'/delete-promise'}
                    render={props => <DeletePromise {...props}/>}
                />
                <Route
                    path={'/delete-account'}
                    render={props => <DeleteAccount {...props}/>}
                />
                {/*<Route component={NotFound} />*/}
                {/*<Route
                    path="/verify-phone"
                    render={(props) => <VerifyPhone {...props} />}
                />*/}
                <Route
                    path="/update-account-verify-phone"
                    render={(props) => <UpdateAccountByVerifyPhone {...props} />}
                />
                <Redirect to={'/'}/>
            </Switch>
        );
    }
}

const mapStateToProps = state => ({
    version: state.auth.version,
});

const mapDispatchToProps = dispatch => ({
    setVersion: (version) => dispatch(setVersion(version)),
    clearCommon: () => dispatch(clearCommon()),
    clearTemplate: () => dispatch(clearTemplate()),
    clearDebt: () => dispatch(clearDebt()),
    clearSignature: () => dispatch(clearSignature())
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(AppRouter));
