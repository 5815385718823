import { handleActions } from 'redux-actions';

const initialState = {
    signers: [],
    pdfFile: undefined,
    pdfFile0: undefined,
    pdfFile1: undefined,
    pdfFile2: undefined,
    pdfFile3: undefined,
    signStatus: 0,
    currentStep: 0,
    eSignId: undefined,
    title: undefined,
    type: undefined,
    pageIndex: 0,
    editing: 0,
};

export default handleActions(
    {
        handleTemplate: (state, action) => ({
            ...state,
            ...action.payload,
        }),
        clearTemplate: (state) => ({
            ...state,
            ...initialState,
        }),
        handlePdf: (state, action) => ({
            ...state,
            ...action.payload,
        }),
        handleEditing: (state, action) => ({
            ...state,
            editing: action.payload,
        })
    },
    initialState,
);
