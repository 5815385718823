import { createActions } from 'redux-actions';
import i18n from '../../i18n';
import api from '../../lib/api';

export const {
    updateUser,
    handleLoggedIn,
    getSignatures,
    removeSignature,
    getPromises,
    removePromise,
    updatePromise,
    getArchivedPromises,
    setIpAddress,
    setLanguage,
    setVersion,
    clearAuth,
    setSnackMessage,
    setMarketingStatus,
} = createActions({
    updateUser: (user) => user,
    handleLoggedIn: (isLoggedIn) => isLoggedIn,
    getSignatures: (signatures) => signatures,
    removeSignature: (signature) => signature,
    getPromises: (promises) => promises,
    removePromise: (promise) => promise,
    updatePromise: (promise) => promise,
    getArchivedPromises: (promises) => promises,
    setIpAddress: (ip) => ip,
    setLanguage: (lang) => lang,
    setVersion: (version) => version,
    clearAuth: () => ({}),
    setSnackMessage: (message) => message,
    setMarketingStatus: (status) => status,
});

export function updatePosition() {
    return (dispatch) => {
        api.getLocation()
            .then((response) => {
                const { data } = response;
                dispatch(setIpAddress(data.ip));
                const lang = data.country === 'KR' ? 'ko' : 'en';
                i18n.changeLanguage(lang);
                dispatch(setLanguage(lang));
            })
            .catch((error) => {
                console.log(error);
            });
    };
}
