import { handleActions } from 'redux-actions';

const initialState = {
    id: undefined,
    signature: undefined,
    userId: undefined,
};

export default handleActions(
    {
        handleSignature: (state, action) => ({
            ...state,
            ...action.payload,
        }),
        clearSignature: (state) => ({
            ...state,
            ...initialState,
        }),
    },
    initialState,
);
