import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
// import 'firebase/messaging';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

class Firebase {
    constructor() {
        app.initializeApp(config);

        // this.emailAuthProvider = app.auth.EmailAuthProvider;
        this.auth = app.auth();
        this.db = app.database();

        // this.emailProvider = new app.auth.EmailAuthProvider();
        // this.googleProvider = new app.auth.GoogleAuthProvider();
        // this.facebookProvider = new app.auth.FacebookAuthProvider();

        // this.messaging = app.messaging();
        // this.messaging.onMessage((payload) => {
        //     console.log(payload);
        // });
    }

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    // doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

    // doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

    doSendEmailVerification = (ret_uri) =>
        this.auth.currentUser.sendEmailVerification({
            url: ret_uri || process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
        });

    // doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

    onAuthUserListener = (next, fallback) =>
        this.auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                this.user(authUser.uid)
                    .once('value')
                    .then((snapshot) => {
                        const dbUser = snapshot.val();
                        if (!dbUser.roles) {
                            dbUser.roles = [];
                        }

                        const auth = {
                            uid: authUser.uid,
                            email: authUser.email,
                            emailVerified: authUser.emailVerified,
                            providerData: authUser.providerData,
                            ...dbUser,
                        };
                        console.log(auth);
                        next(auth);
                    });
            } else {
                fallback();
            }
        });

    user = (uid) => this.db.ref(`users/${uid}`);

    users = () => this.db.ref('users');

    readChat = (channel, next, failure) => {
        const abc = [];
        try {
            this.db.ref(`chats/${channel}`).on('value', (snapshot) => {
                snapshot.forEach((snap) => {
                    abc.push(snap.val());
                });
                next(abc);
            });
        } catch (e) {
            failure(e);
        }
    };

    writeMessage = (channel, message) =>
        this.db.ref(`chats/${channel}`).push({
            sender: message.sender,
            content: message.content,
            timestamp: message.timestamp,
            uid: message.uid,
        });

    /* getToken = () => {
        return new Promise((resolve, reject) => {
            this.messaging.getToken()
                .then((currentToken) => {
                    resolve(currentToken);
                })
                .catch((err) => {
                    reject(err)
                });
        });
    }; */

    // onMessageListener = () => {
    //     return new Promise((resolve) => {
    //         this.messaging.onMessage((payload) => {
    //             resolve(payload);
    //         });
    //     });
    // }
}

export default Firebase;
