import React from 'react';
import ReactDOM from 'react-dom';

import { ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { hotjar } from 'react-hotjar';
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';
import App from './App';
import Theme from './Theme';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Firebase, { FirebaseContext } from './components/Firebase';
import configStore from './redux/index';
import './i18n';
import 'core-js';
import './assets/fonts/font.css';

const { store, persistor } = configStore();

const tagManagerArgs = {
    gtmId: 'GTM-WCQZVL3'
};

TagManager.initialize(tagManagerArgs);

const pixelOptions = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
};

ReactPixel.init(
    '550734993133725',
    {},
    pixelOptions
);

ReactPixel.pageView();

ReactDOM.render(
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <FirebaseContext.Provider value={new Firebase()}>
        <ThemeProvider theme={Theme}>
            <Provider store={store}>
                <App />
            </Provider>
        </ThemeProvider>
    </FirebaseContext.Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const isProduction = process.env.REACT_APP_ENV === 'production';
// disable console.log on prod.
function silent() {}
if (isProduction) {
    console.log = silent;
    console.warn = silent;
    console.error = silent;
}
console.log('isProduction>>>', isProduction);
// disable hotjar on local & stage.
if (isProduction) {
    hotjar.initialize(2634664, 6);
}
