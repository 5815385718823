import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import ResourceEn from './assets/lang/en/translation.json';
import ResourceKo from './assets/lang/ko/translation.json';

const fallbackLng = ['en'];
const availableLanguages = ['en', 'ko', 'km'];
const detectionOptions = {
    order: [
        'path',
        'cookie',
        'localStorage',
        'navigator',
        'subdomain',
        'queryString',
        'htmlTag',
    ],
    lookupFromPathIndex: 0,
};

const resources = {
    en: {
        translation: ResourceEn,
    },
    ko: {
        translation: ResourceKo,
    },
    km: {
        translation: ResourceEn,
    },
};

i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng,
        whitelist: availableLanguages,
        debug: true,
        detection: detectionOptions,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: resources.en,
            ko: resources.ko,
            km: resources.en,
        },
    });

export default i18n;
