import { applyMiddleware, createStore, combineReducers, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import ReduxThunk from 'redux-thunk';
import logger from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import auth from './auth/reducer';
import history from './history/reducer';
import common from './common/reducer';
import debt from './debt/reducer';
import signature from './signature/reducer';
import template from './template/reducer';

export default function configureStore() {
    const isDev =
        !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'development';
    const middleware = [
        isDev && logger,
        createStateSyncMiddleware({
            blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
        })].filter(Boolean);
    const middlewareEnhancers = applyMiddleware(ReduxThunk, ...middleware);

    const enhancers = [middlewareEnhancers];
    const composedEnhancers = compose(...enhancers);

    const persistConfig = {
        key: 'pinkiiswear',
        storage,
    };

    const appReducer = combineReducers({
        auth,
        history,
        common,
        debt,
        signature,
        template,
    });

    const rootReducer = (state, action) => {
        let reState = state;
        if (action.type === 'handleLoggedIn' && action.payload === false) {
            reState = undefined;
        }
        return appReducer(reState, action);
    };

    const persistedReducer = persistReducer(persistConfig, rootReducer);

    const store = createStore(persistedReducer, undefined, composedEnhancers);
    initMessageListener(store);
    const persistor = persistStore(store);
    return { store, persistor };
}

/* // export default combineReducers({
//     auth: authStateReducer
// }); */
