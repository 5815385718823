import { createActions } from 'redux-actions';
import api from '../../lib/api';

export const {
    handleError,
    handleSuccess,
    handleSinglePromise,
    clearSinglePromise,
    handleSentPromises,
    removeSentPromise,
    updateSentPromise,
    handleReceivedPromises,
    removeReceivedPromise,
    updateReceivedPromise,
    removePromise,
    updatePromise,
    setFabricObjects,
    clearFabricObjects,
    addFabricObject,
    removeFabricObject,
    updateFabricObject,
    updateFabricCanvas,
    clearFabricCanvas,
    handleLoading,
    handleNotification,
    handleNotificationList,
    removeNotification,
    clearNotification,
    handleReadNotification,
    clearCommon,
    addMessageToShown,
} = createActions({
    handleError: (showError, message) => ({ showError, message }),
    handleSuccess: (showSuccess, message) => ({ showSuccess, message }),
    handleSinglePromise: (promise) => promise,
    clearSinglePromise: () => ({}),
    handleSentPromises: (promises) => promises,
    removeSentPromise: (promise) => promise,
    updateSentPromise: (promise) => promise,
    handleReceivedPromises: (promises) => promises,
    removeReceivedPromise: (promise) => promise,
    updateReceivedPromise: (promise) => promise,
    removePromise: (promise) => promise,
    updatePromise: (promise) => promise,
    setFabricObjects: (objects) => objects,
    addFabricObject: (object) => object,
    removeFabricObject: (object) => object,
    updateFabricObject: (object) => object,
    clearFabricObjects: () => ({}),
    updateFabricCanvas: (object) => object,
    clearFabricCanvas: () => ({}),
    handleLoading: (show) => show,
    handleNotification: (show) => show,
    handleNotificationList: (list) => list,
    removeNotification: (notifyId) => notifyId,
    clearNotification: () => ({}),
    handleReadNotification: (read) => read,
    clearCommon: () => ({}),
    addMessageToShown: (message) => (message),
});

export function setFabric(data) {
    return (dispatch) => {
        // console.log(objects);
        dispatch(handleLoading(true));
        api.singlePromiseEditor(data)
            // eslint-disable-next-line no-shadow
            .then(({ data }) => {
                console.log(data);
                dispatch(
                    handleSinglePromise({
                        currentStep: data.data.currentStep,
                    }),
                );
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    dispatch(handleError(true, error.response.data.message));
                } else {
                    dispatch(handleError(true, 'Something went wrong'));
                }
            });
        dispatch(handleLoading(false));
    };
}
