import { handleActions } from 'redux-actions';

const initialState = {
    id: undefined,
    type: undefined,
    firstName: '',
    lastName: '',
    promisorName: '',
    phoneNumber: '',
    title: '',

    amount: '',
    interestRate: undefined,
    interestAmount: undefined,
    contents: '',
    debtPeriod: 'Year',
    paybackDay: '',

    promiseDueDate: new Date(),

    description: '',
    attachedFile: undefined,

    signStatus: 0,
    borrowerId: undefined,
    lenderId: undefined,
    borrowerSignAgree: false,
    lenderSignAgree: false,
    borrowerSignature: undefined,
    lenderSignature: undefined,
    borrowerSignatureId: undefined,
    lenderSignatureId: undefined,

    fabricObjects: [],
    canvasWidth: 400,
    canvasHeight: 600,

    currentStep: 0,
    rates: [],
    file: undefined,
    fileId: undefined,
    downPayment: 0,

    editing: 0,
};

export default handleActions(
    {
        handleDebt: (state, action) => ({
            ...state,
            ...action.payload,
        }),
        clearDebt: (state) => ({
            ...state,
            ...initialState,
        }),
        handleDebtEditing: (state, action) => ({
            ...state,
            editing: action.payload,
        }),
    },
    initialState,
);
