/* eslint-disable no-param-reassign */
import { handleActions } from 'redux-actions';

const initialStatus = {};

export default handleActions(
    {
        updateCallHistory: (state, action) => {
            const { numberId, direction, calls } = action.payload;
            if (!state[numberId]) {
                state[numberId] = {
                    all: [],
                    incoming_calls: [],
                    outgoing_calls: [],
                };
            }
            state[numberId][direction] = calls;
            return { ...state };
        },
    },
    initialStatus,
);
